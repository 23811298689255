import { createContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { useLoggedIn } from 'bv-hooks';
import { v as bvVar } from 'bv';
import { listComponent } from 'Offers/config';
import WalletsList from 'Offers/components/wallet/wallets_list';
import OfferDetail from 'Offers/components/offer_detail';
import WalletContainer from 'Offers/components/wallet/wallet_container';
import { resetApp as resetAppAction, fetchBalance as fetchBalanceAction } from 'Offers/duck';

const OffersContext = createContext();

const filters = bvVar('offerFilterSettings') || [];

const productKeys = filters.map(({ product_key: productKey }) => productKey).join('|');

const App = ({ id, fetchBalance, resetApp }) => {
  const loggedIn = useLoggedIn();
  const rootPath = id === 'signup' ? '/account/offers' : '/offer';
  const offersPath = productKeys ? `${rootPath}/:product(${productKeys})?` : rootPath;

  useEffect(() => {
    if (loggedIn) {
      fetchBalance();
    }
    return resetApp;
  }, [loggedIn]);

  const contextValue = useMemo(
    () => ({ appId: id }),
    [id],
  );

  return (
    <OffersContext.Provider value={contextValue}>
      <Switch>
        <Route exact path={offersPath} component={listComponent(id)} />
        <Route exact path="/offer/wallets">
          <WalletsList appId={id} />
        </Route>
        <Route exact path="/offer/wallets/:id_for_cpbt?">
          <WalletContainer appId={id} />
        </Route>
        <Route exact path={`${rootPath}/:slug/:id?`}>
          <OfferDetail appId={id} rootPath={rootPath} />
        </Route>
      </Switch>
    </OffersContext.Provider>
  );
};

App.propTypes = {
  resetApp: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  fetchBalance: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetApp: () => dispatch(resetAppAction(ownProps.id)),
  fetchBalance: () => dispatch(fetchBalanceAction(ownProps.id)),
});

export default connect(null, mapDispatchToProps)(App);
export { OffersContext };
