import PropTypes from 'prop-types';
import { Carousel } from 'bv-components';
import { v as bvVar } from 'bv';
import OffersFilter from './offers_filter';

const filters = bvVar('offerFilterSettings');

const OffersFilters = ({ selectedFilter }) => filters.length > 1 && (
  <Carousel
    className="site-secondary-nav-wrapper offers-section-tabs"
    scrollStep={110}
    embedded
  >
    { filters.map(({
      id, product_key: productKey, name,
    }) => (
      <OffersFilter
        key={id}
        name={name}
        product={productKey}
        selected={selectedFilter.toLowerCase() === productKey.toLowerCase()}
      />
    ))}
  </Carousel>
);

OffersFilters.propTypes = {
  selectedFilter: PropTypes.string.isRequired,
};

export default OffersFilters;
