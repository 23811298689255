import PropTypes from 'prop-types';
import CtaButton from 'Offers/components/cta_button';
import { v as bvVar } from 'bv';
import { t } from 'bv-i18n';

const NotFoundRecord = ({ cta_button: ctaButton, message, title }) => (
  <div className="not-found-offer error-page-wrapper site-error-wrapper__content cF">
    <div className="site-error-message error_message site-error-wrapper__error-message">
      <span className="error_bv_icon" />
      <div className="error_message_content">
        <h1 className="bvs-h1 error-page-header error_bv_title">
          <span>{title}</span>
        </h1>
        <p>{message}</p>
        <CtaButton
          {...ctaButton}
        />
      </div>
    </div>

    {bvVar('helpEmail')
      && (
      <div className="error-contact-wrapper">
        <div className="contact-error-title">{t('javascript.error_page.contact_us')}</div>
        <a className="error-page-email" href={`mailto:${bvVar('helpEmail')}`}>
          {bvVar('helpEmail')}
        </a>
      </div>
      )}

  </div>
);

NotFoundRecord.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  cta_button: PropTypes.instanceOf(Object),
};

NotFoundRecord.defaultProps = {
  cta_button: {},
};

export default NotFoundRecord;
