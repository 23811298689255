import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { countryCode, loggedIn } from 'bv-helpers/session';
import { v as bvVar } from 'bv';
import { setScroll, fetchOffer } from 'Offers/duck';
import { getScroll, getOffer } from 'Offers/selectors';

export const offerLink = (appId, offer, bonusCode = null) => {
  const rootPath = appId === 'signup' ? '/account/offers' : '/offer';
  const bonusCodePart = bonusCode ? `?bonus_code=${bonusCode}` : '';
  return offer.id
    ? `${rootPath}/${offer.slug}/${offer.id}${bonusCodePart}`
    : `${rootPath}/${offer.slug}${bonusCodePart}`;
};

const getScrollNode = (ref) => {
  if (ref && ref.current) {
    let { current } = ref;
    while (current.parentNode !== null && current.parentNode !== document.documentElement) {
      if (/modal_contents_wrapper/.test(current.className)) {
        return current;
      }
      current = current.parentNode;
    }
  }

  return document.documentElement;
};

export const useSaveScrollPos = ({ appId, ref }) => {
  const dispatch = useDispatch();
  const scroll = useSelector((state) => getScroll(state, appId));

  const goToScrollPos = () => {
    getScrollNode(ref).scrollTo(0, scroll);
  };

  useEffect(() => {
    goToScrollPos();
  }, []);

  const saveScrollPos = () => {
    dispatch(setScroll(appId, getScrollNode(ref).scrollTop));
  };

  return saveScrollPos;
};

export const refreshCurrentOffer = ({ ignoreErrors } = { ignoreErrors: false }) => {
  const appIds = ['signup', 'main'];
  const { dispatch } = window.reduxState.store;
  const state = window.reduxState.store.getState();

  appIds.forEach((appId) => {
    const offer = getOffer(state, appId);
    if (offer) {
      dispatch(fetchOffer(appId, offer.slug, offer.id, ignoreErrors));
    }
  });
};

export const showTaxMessage = () => loggedIn() && countryCode() === 'DE';

export const getDefaultProduct = () => bvVar('offerFilterSettings')[0]?.product_key;

/* eslint import/prefer-default-export: off */
