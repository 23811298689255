import { createRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useSaveScrollPos, offerLink } from 'Offers/helpers';
import { getBonusOffersResp, getBonusCodeInput } from 'Offers/selectors';
import { Link } from 'bv-components';

import SignupOffer from '../signup_offer';
import Offer from '../offer';
import OffersNotFound from '../offers_not_found';

const BonusCodeOffersList = ({ appId }) => {
  const ref = createRef();
  const { offers } = useSelector((state) => getBonusOffersResp(state, appId)) || {};
  const bonusCodeInput = useSelector((state) => getBonusCodeInput(state, appId));
  const saveScrollPos = useSaveScrollPos({ appId, ref });

  if (offers) {
    if (offers.length === 0) {
      return <OffersNotFound />;
    }

    return (
      <div className="offers-main-list bonus-list" ref={ref}>
        { offers.map((offer) => (
          <Link to={offerLink(appId, offer, bonusCodeInput.bonus_code)} data-react="true" onClick={saveScrollPos} key={offer.id}>
            { appId === 'signup'
              ? <SignupOffer {...offer} key={offer.slug} appId={appId} />
              : <Offer {...offer} key={offer.slug} appId={appId} />}
          </Link>
        ))}
      </div>
    );
  }

  return null;
};

BonusCodeOffersList.propTypes = {
  appId: PropTypes.string.isRequired,
};

export default BonusCodeOffersList;
