import { t } from 'bv-i18n';
import { optInHandler } from 'Offers/config';
import baseHandler from './base_handler';

const defaultHandler = baseHandler(
  'javascript.promo_hub.opt_in.processing',
  (response) => (response.code && response.description ? response.description : t('javascript.promo_hub.opt_in.failed')),
);

export default (appId) => optInHandler(appId) || defaultHandler;
