import PropTypes from 'prop-types';
import BackLink from 'Offers/components/back_link';
import BalanceInfo from './balance_info';

const BonusBalance = ({ goBack }) => (
  <div className="offers-item">
    <div className="offers-banner">
      <div className="offers-banner__details">
        <BalanceInfo goBack={goBack} />
      </div>

      {goBack && <BackLink linkTo="/offer" />}
    </div>
  </div>
);

BonusBalance.propTypes = {
  goBack: PropTypes.bool,
};

BonusBalance.defaultProps = {
  goBack: false,
};

export default BonusBalance;
