import { t } from 'bv-i18n';
import { Card } from 'bv-components';

const OffersNotFound = () => (
  <div className="offers-main-list no-offers">
    <Card>
      {' '}
      {t('javascript.promo_hub.no_available_promotions')}
      {' '}
    </Card>
  </div>
);

export default OffersNotFound;
