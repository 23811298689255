import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'bv-components';

const OffersFilter = ({ product, name, selected }) => {
  const className = classnames('bvs-button-chip offers-filter-button', {
    active: selected,
  });

  return (
    <Button
      className={className}
      noClass
      to={{ pathname: `/offer/${product.toLowerCase()}` }}
      data-react="true"
    >
      {name}
    </Button>
  );
};

OffersFilter.propTypes = {
  name: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default OffersFilter;
