import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLoggedIn } from 'bv-hooks';
import { toCurrency } from 'bv-helpers/number';
import { handleCta } from 'bv-services';
import { Icon, Link } from 'bv-components';
import { getBalance } from 'Offers/selectors';
import { t } from 'bv-i18n';

const BalanceInfo = ({ goBack }) => {
  const loggedIn = useLoggedIn();
  const balance = useSelector((state) => getBalance(state, 'main')) || '---';

  const detailOnClick = (event) => {
    if (!loggedIn) {
      handleCta({
        action: 'ACCOUNTSECTION',
        accountSection: 'LOGIN',
      });

      event.preventDefault();
    }
  };

  const info = ([
    <Icon id="info" type="offers-info-icon" big />,
    <Icon id="offers" type="offers-nav-icon" big />,
    <div>
      <div className="offers-banner__details-balance">{toCurrency(balance, { decimalPlaces: 2 })}</div>
      <div className="offers-banner__details-desc">{t('javascript.promo_hub.see_bonus_details')}</div>
    </div>,
  ]);

  if (!goBack) {
    return (
      <Link to="/offer/wallets" data-react="true" onClick={detailOnClick} className="offers-banner__details-link bvs-button-card is-large">
        {info}
      </Link>
    );
  }

  return (
    <div className="offers-banner__details-link bvs-button-card is-large">
      {info}
    </div>
  );
};

BalanceInfo.propTypes = {
  goBack: PropTypes.bool,
};

BalanceInfo.defaultProps = {
  goBack: false,
};

export default BalanceInfo;
