import { flatten } from 'underscore';
import {
  doFetchOffers, doFetchOffer, doOptIn, doFetchBalance, doFetchWallet, doFetchWallets, doClaim,
} from 'Offers/api';
import { shouldFetchOffers } from 'Offers/selectors';
import handleOptIn from 'Offers/handlers/opt_in';
import handleClaim from 'Offers/handlers/claim';
import { addOutcomes } from 'sportsbook-outcomes-duck';
import { getDefaultProduct, refreshCurrentOffer } from 'Offers/helpers';

// ===================
// App action creators
// ===================

const ADD_APP = 'offers/ADD_APP';
const RESET_APP = 'offers/RESET_APP';

export const addApp = (id) => ({
  type: ADD_APP,
  id,
});

export const resetApp = (id) => ({
  type: RESET_APP,
  id,
});

// ==============================
// List of offers action creators
// ==============================

const REQUEST_OFFERS = 'offers/REQUEST_OFFERS';
const RECEIVE_OFFERS = 'offers/RECEIVE_OFFERS';
const RECEIVE_BONUS_OFFERS = 'offers/RECEIVE_BONUS_OFFERS';
const OFFER_NOT_FOUND = 'offers/OFFER_NOT_FOUND';

export const requestOffers = (id) => ({
  type: REQUEST_OFFERS,
  id,
});

export const receiveOffers = (id, offersResp = {}) => ({
  type: RECEIVE_OFFERS,
  id,
  offers: offersResp.offers,
  bonusCodeInput: offersResp.bonus_code_input || {},
  error: offersResp.error,
});

export const receiveBonusOffersResp = (id, bonusOffersResp = {}) => ({
  type: RECEIVE_BONUS_OFFERS,
  id,
  bonusOffersResp,
});

export const offerNotFound = () => ({
  type: OFFER_NOT_FOUND,
});

const fetchOffers = (appId) => (dispatch) => {
  dispatch(requestOffers(appId));

  doFetchOffers(appId).then((json) => {
    dispatch(receiveOffers(appId, json));
  });
};

export const fetchOffersIfNeeded = (appId) => (dispatch, getState) => (
  shouldFetchOffers(getState(), appId) && dispatch(fetchOffers(appId))
);

// ============================
// Offer detail action creators
// ============================

const REQUEST_OFFER = 'offers/REQUEST_OFFER';
export const RECEIVE_OFFER = 'offers/RECEIVE_OFFER';
const RESET_OFFER = 'offers/RESET_OFFER';
const SET_SCROLL = 'offers/SET_SCROLL';

export const requestOffer = (id) => ({
  type: REQUEST_OFFER,
  id,
});

export const receiveOffer = (id, offer) => ({
  type: RECEIVE_OFFER,
  id,
  offer,
});

export const resetOffer = (id) => ({
  type: RESET_OFFER,
  id,
});

export const setScroll = (id, scroll) => ({
  type: SET_SCROLL,
  id,
  scroll,
});

export const fetchOffer = (
  appId, slug, id, bonusCode = null, ignoreErrors = false,
) => (dispatch) => {
  dispatch(requestOffer(appId));

  return doFetchOffer(appId, slug, id, bonusCode, ignoreErrors).then((json) => {
    if (json === undefined) {
      dispatch(offerNotFound());
      return false;
    }

    const outcomes = flatten((json.steps || []).map((step) => step.enhancedOutcomes || []));
    dispatch(addOutcomes(outcomes.map((o) => ({ id: o.outcomeId, ...o }))));
    dispatch(receiveOffer(appId, json));

    return json;
  });
};

// ======================
// Opt in action creators
// ======================

const REQUEST_OPT_IN = 'offers/REQUEST_OPT_IN';
export const RECEIVE_OPT_IN = 'offers/RECEIVE_OPT_IN';

export const requestOptIn = (id, offerId, slug, bonusCode) => ({
  type: REQUEST_OPT_IN,
  id,
  offerId,
  slug,
  bonusCode,
});

export const receiveOptIn = (id, offerId, slug, bonusCode) => ({
  type: RECEIVE_OPT_IN,
  // these fields are used in middleware
  id,
  offerId,
  slug,
  bonusCode,
});

export const optIn = (appId, id, slug, bonusCode) => (dispatch) => {
  dispatch(requestOptIn(appId, id, slug, bonusCode));

  doOptIn(id, bonusCode)
    .then(handleOptIn(appId))
    .then(() => dispatch(receiveOptIn(appId, id, slug, bonusCode)))
    .then(() => {
      // offer-reload happens via full-page-reload on main app
      if (appId === 'signup') refreshCurrentOffer({ ignoreErrors: true });
    });
};

// =======================
// Wallets action creators
// =======================

const REQUEST_WALLET = 'offers/REQUEST_WALLET';
const RECEIVE_WALLET = 'offers/RECEIVE_WALLET';
const REQUEST_WALLET_FAILURE = 'offers/REQUEST_WALLET_FAILURE';

export const requestWallet = () => ({
  type: REQUEST_WALLET,
});

export const receiveWallet = (wallet) => ({ type: RECEIVE_WALLET, wallet });

export const requestWalletFailure = () => ({ type: REQUEST_WALLET_FAILURE });

export const fetchWallet = (id) => (dispatch) => {
  dispatch(requestWallet());
  return doFetchWallet(id)
    .then((data) => dispatch(receiveWallet(data)))
    .catch(() => dispatch(requestWalletFailure()));
};

const ADD_BALANCE = 'offers/ADD_BALANCE';

export const addBalance = (id, balance) => ({
  type: ADD_BALANCE,
  id,
  balance,
});

export const fetchBalance = (appId) => (dispatch) => {
  doFetchBalance().then((balance) => dispatch(addBalance(appId, balance)));
};

const REQUEST_WALLETS = 'offers/REQUEST_WALLETS';
const RECEIVE_WALLETS = 'offers/RECEIVE_WALLETS';

export const requestWallets = (id) => ({
  type: REQUEST_WALLETS,
  id,
});

export const receiveWallets = (id, wallets) => ({
  type: RECEIVE_WALLETS,
  id,
  wallets,
});

export const fetchWallets = (appId) => (dispatch) => {
  dispatch(requestWallets(appId));

  doFetchWallets(appId).then((json) => {
    dispatch(receiveWallets(appId, json));
  });
};

// ======================
// Claim action creators
// ======================

const REQUEST_CLAIM = 'offers/REQUEST_CLAIM';
const RECEIVE_CLAIM = 'offers/RECEIVE_CLAIM';

export const requestClaim = (id) => ({
  type: REQUEST_CLAIM,
  id,
});

export const receiveClaim = (id) => ({
  type: RECEIVE_CLAIM,
  id,
});

export const claim = (appId, id) => (dispatch) => {
  dispatch(requestClaim(appId));

  doClaim(id)
    .then(handleClaim)
    .then(() => dispatch(receiveClaim(appId)));
};

// ========
// Reducers
// ========

const applyChangesToApp = (state, appId = 'main') => (changes) => ({
  ...state,
  [appId]: {
    ...(state[appId]),
    ...(changes),
  },
});

export default (state = {}, action = {}) => {
  const modifyState = applyChangesToApp(state, action.id);

  switch (action.type) {
    case ADD_APP:
      return modifyState({
        list: [],
        didInvalidate: false,
        selectedFilter: getDefaultProduct(),
        isOptingIn: false,
        optInOfferId: null,
        wallets: [],
        isClaiming: false,
        scroll: 0,
      });
    case RESET_APP: {
      const { [action.id]: deleted, ...newState } = state;

      return newState;
    }
    case REQUEST_OFFERS:
      return modifyState({
        isFetching: true,
        didInvalidate: false,
      });
    case RECEIVE_OFFERS:
      return modifyState({
        list: action.offers,
        bonusCodeInput: { ...(state[action.id] || {}).bonusCodeInput, ...action.bonusCodeInput },
        error: action.error,
        isFetching: false,
        didInvalidate: false,
      });
    case RECEIVE_BONUS_OFFERS:
      return modifyState({
        bonusCodeInput: action.bonusOffersResp.bonus_code_input,
        bonusOffersResp: action.bonusOffersResp,
      });
    case REQUEST_OFFER:
      return modifyState({
        isFetching: true,
        offer: null,
      });
    case RECEIVE_OFFER:
      return modifyState({
        isFetching: false,
        offer: action.offer,
      });
    case OFFER_NOT_FOUND:
      return modifyState({
        isFetching: false,
      });
    case SET_SCROLL:
      return modifyState({ scroll: action.scroll });
    case RESET_OFFER:
      return modifyState({
        isFetching: null,
        offer: null,
      });
    case REQUEST_OPT_IN:
      return modifyState({ isOptingIn: true, optInOfferId: action.offerId });
    case RECEIVE_OPT_IN:
      return modifyState({ isOptingIn: false, optInOfferId: null });
    case ADD_BALANCE:
      return modifyState({ balance: action.balance });
    case REQUEST_WALLETS:
      return modifyState({ isFetching: true });
    case RECEIVE_WALLETS:
      return modifyState({
        wallets: action.wallets,
        isFetching: false,
      });
    case REQUEST_CLAIM:
      return modifyState({ isClaiming: true });
    case RECEIVE_CLAIM:
      return modifyState({ isClaiming: false });
    case REQUEST_WALLET:
      return modifyState({ isFetching: true, wallet: {} });
    case RECEIVE_WALLET:
      return modifyState({ isFetching: false, wallet: action.wallet });
    case REQUEST_WALLET_FAILURE:
      return modifyState({ isFetching: false, wallet: {} });
    default:
      return state;
  }
};
