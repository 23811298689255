import PropTypes from 'prop-types';

import { Button } from 'bv-components';
import { handleCta } from 'bv-services';

const CtaButton = (props) => {
  const { buttonType, className, name } = props;
  return (
    <Button
      className={className}
      label={name}
      onClick={
        (event) => {
          event.preventDefault();
          handleCta(props);
        }
      }
      primary
      type={buttonType}
    />
  );
};

CtaButton.defaultProps = {
  buttonType: null,
};

CtaButton.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  buttonType: PropTypes.string,
};

export default CtaButton;
