import { Spinner } from 'bv-components';
import { useFetch } from 'bv-hooks';
import BonusWrapper from 'Offers/components/bonus_wrapper';
import Banner from 'Offers/components/banner';
import fetchBanners from '../../../globals/components/banners/api/fetch_banners.js';

const BannerContainer = () => {
  const [banners, loading] = useFetch(() => fetchBanners('Offers'), []);

  if (loading) return <Spinner />;

  return banners.length > 0
    ? <Banner />
    : <BonusWrapper />;
};

export default BannerContainer;
