import { t } from 'bv-i18n';
import { isDesktop } from 'bv';
import { toCurrency } from 'bv-helpers/number';
import { add } from 'bv-services/betslip-adapter';
import { setBetslipTooltip } from 'floating-navigation-helpers';
import { updateSeoEntity } from 'Seo/duck';
import { fetchOffer, RECEIVE_OPT_IN, RECEIVE_OFFER } from './duck';
import { showTaxMessage } from './helpers';

let lastOptinEnhancedOutcome = null;

const addEnhancedOutcomeToBetslip = (store, {
  id, offerId, slug, bonusCode,
}) => {
  // only signup promos should have chance to show these tooltips

  if (id !== 'signup') return;
  const ignoreErrors = true;

  fetchOffer(id, slug, offerId, bonusCode, ignoreErrors)(store.dispatch).then((json) => {
    const outcomes = (json?.steps || []).reduce((acc, step) => (
      [
        ...acc,
        ...step.enhancedOutcomes || [],
      ]
    ), []).flat();
    outcomes.forEach(
      (outcome) => add(
        store,
        outcome.outcomeId,
        {
          forceNormalBetslip: true,
          offer: { enhancedOutcomes: { [outcome.outcomeId]: outcome } },
        },
      ),
    );
    [lastOptinEnhancedOutcome] = outcomes;
  });
};

const showEnhancedOddsTooltip = (oldPath, currentPath) => {
  if (isDesktop()) {
    return;
  }
  // navigating from bv_cashier to anywhere else (closing the cashier dialog)
  if (lastOptinEnhancedOutcome && oldPath.match(/bv_cashier/) && !currentPath.match(/bv_cashier/)) {
    const { maxStake } = lastOptinEnhancedOutcome;
    const priceParts = lastOptinEnhancedOutcome.fractionalEnhancedOdds.split('/');
    const enhancedPrice = Math.round((1 + (parseInt(priceParts[0], 10)
      / parseInt(priceParts[1], 10))) * 1000) / 1000;

    // TODO when the tax status will be refactored to a setting
    // it will have to change too
    const isTaxed = showTaxMessage();

    setBetslipTooltip(
      `
        ${t('javascript.betslip_enhanced_odds_tooltip', {
    description: lastOptinEnhancedOutcome.outcomeDescription,
    stake: toCurrency(maxStake),
    returns: toCurrency(enhancedPrice * maxStake),
  })}
        <br>
        ${isTaxed ? t('javascript.betslip_tax_info_collapse') : ''}
      `,
      { duration: 10000, className: 'multiline' },
    );

    lastOptinEnhancedOutcome = null;
  }
};

const addSeoTags = (store, { offer }) => {
  store.dispatch(updateSeoEntity({ unavailableAfter: offer.endsAt }));
};

const offersMiddleware = (store) => (next) => (action) => {
  const oldPath = store.getState().routing.currentPath;
  next(action);
  const { currentPath } = store.getState().routing;

  switch (action.type) {
    case RECEIVE_OPT_IN:
      addEnhancedOutcomeToBetslip(store, action);
      break;
    case RECEIVE_OFFER:
      addSeoTags(store, action);
      break;
    case 'routing/REFRESH_CURRENT_PATH':
      showEnhancedOddsTooltip(oldPath, currentPath);
      break;
    default:
      break;
  }
};

export default offersMiddleware;
