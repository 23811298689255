import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import WalletPageParent from 'Offers/components/wallet/wallet_page_parent';
import { ContentLayout } from 'bv-components';
import { fetchWallet as fetchWalletAction } from 'Offers/duck';
import { getWallet, getIsFetching } from 'Offers/selectors';
import { showBackButton } from 'Offers/config';

const WalletContainer = ({ fetchWallet, isFetching, wallet }) => {
  const { id_for_cpbt: id } = useParams();

  useEffect(() => { fetchWallet(id); }, [id]);

  return (
    <ContentLayout>
      <WalletPageParent
        wallet={wallet}
        isFetching={isFetching}
        showBackButton={showBackButton('main')}
      />
    </ContentLayout>
  );
};

WalletContainer.propTypes = {
  fetchWallet: PropTypes.func.isRequired,
  wallet: PropTypes.instanceOf(Object),
  isFetching: PropTypes.bool.isRequired,
};

WalletContainer.defaultProps = {
  wallet: null,
};

const mapStateToProps = (state, ownProps) => ({
  wallet: getWallet(state, ownProps.appId),
  isFetching: getIsFetching(state, ownProps.appId),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchWallet: (id) => dispatch(fetchWalletAction(id, ownProps.appId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletContainer);
