import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import { t } from 'bv-i18n';
import { Card, Spinner, ExpandCollapseCard } from 'bv-components';
import WalletInfo from 'Offers/components/wallet/wallet_info';
import NotFoundRecord from 'Offers/components/not_found_record';
import BackLink from 'Offers/components/back_link';
import ReactMarkdown from 'react-markdown';

const WalletPageParent = (props) => {
  const { isFetching, showBackButton, wallet } = props;
  const notFoundWallet = {
    title: t('javascript.promo_hub.wallet_not_found.title'),
    message: t('javascript.promo_hub.wallet_not_found.message'),
    cta_button: {
      name: t('javascript.promo_hub.wallet_not_found.see_all_wallets'),
      action: 'WALLET',
    },
  };

  const backLink = () => (
    showBackButton
      && (
      <BackLink
        containerClass="wallets-back"
        linkTo="/offer/wallets"
      />
      )
  );

  if (isFetching) {
    return <Spinner />;
  }

  if (!isEmpty(wallet)) {
    return (
      <div className="wallets-card-details">
        {backLink()}

        <Card className="wallets-card">
          <WalletInfo {...props} />
        </Card>

        <ExpandCollapseCard fadeOutOnEnd>
          <ReactMarkdown
            source={wallet.termsAndConditions}
            softBreak="br"
            className="wallets-card-terms page-contents"
          />
        </ExpandCollapseCard>
      </div>
    );
  }

  return (
    <div className="wallets-card-details no-offer">
      {backLink()}
      <NotFoundRecord {...notFoundWallet} />
    </div>
  );
};

WalletPageParent.propTypes = {
  wallet: PropTypes.instanceOf(Object),
  isFetching: PropTypes.bool.isRequired,
  showBackButton: PropTypes.bool.isRequired,
};

WalletPageParent.defaultProps = {
  wallet: {},
};

export default WalletPageParent;
