import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import { claim as claimAction } from 'Offers/duck';

const ClaimButton = ({ claim, isClaiming }) => (
  <Button
    label={t('javascript.promo_hub.claim_now')}
    onClick={claim}
    loading={isClaiming}
    warning
  />
);

ClaimButton.propTypes = {
  claim: PropTypes.func.isRequired,
  isClaiming: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  isClaiming: !!(state[ownProps.appId] && state[ownProps.appId].isClaiming),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  claim: (event) => {
    event.preventDefault();
    dispatch(claimAction(ownProps.appId, ownProps.id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimButton);
