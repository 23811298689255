import { getJSON } from 'bv-fetch';

const promises = {};

export default (currentPath) => {
  promises[currentPath] = promises[currentPath] || getJSON('/api/seo_entity', {
    mobile_path: currentPath,
  });

  return promises[currentPath];
};
