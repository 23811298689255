import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { t } from 'bv-i18n';
import { getBonusCodeInput, getBonusOffersResp } from 'Offers/selectors';
import { doFetchOffers } from 'Offers/api';
import { receiveBonusOffersResp } from 'Offers/duck';

import BonusCodeFormView from 'Offers/components/bonus_code_form_view';

const BonusCodeFormContainer = ({ appId }) => {
  const bonusCodeInput = useSelector((state) => getBonusCodeInput(state, appId));
  const offersResp = useSelector((state) => getBonusOffersResp(state, appId)) || {};
  const [bonusCode, setBonusCode] = useState(bonusCodeInput.bonus_code);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const onSubmit = (e) => {
    e.preventDefault();

    if (bonusCode === '') {
      setError(t('javascript.promo_hub.bonus_code_input.required'));
      return;
    }

    setLoading(true);
    dispatch(receiveBonusOffersResp(appId, { bonus_code_input: bonusCodeInput }));

    doFetchOffers(appId, bonusCode).then((resp) => {
      setLoading(false);

      if (resp.error) {
        setError(resp.error.description);
        return;
      }

      setError(null);
      dispatch(receiveBonusOffersResp(appId, resp));
    });
  };

  const onErrorClick = (e) => {
    e.preventDefault();
    setBonusCode('');
    setError(null);
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onBonusCodeChange = (code) => {
    setBonusCode(code);
    if (code === '') {
      setError(null);
    }
  };

  return (
    <BonusCodeFormView
      bonusCode={bonusCode}
      error={error}
      onErrorClick={onErrorClick}
      inputRef={inputRef}
      loading={loading}
      offersResp={offersResp}
      inputFocused={inputFocused}
      setInputFocused={setInputFocused}
      onSubmit={onSubmit}
      onBonusCodeChange={onBonusCodeChange}
    />
  );
};

BonusCodeFormContainer.propTypes = {
  appId: PropTypes.string.isRequired,
};

export default BonusCodeFormContainer;
