import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { v as bvVar } from 'bv';
import { t } from 'bv-i18n';
import { Button, Spinner } from 'bv-components';
import { getOffers, getBonusCodeInput } from 'Offers/selectors';
import { navigateToDeposit, userName } from 'Offers/config';

import OffersList from './offers_list';
import SignupOffer from '../signup_offer';
import BonusCodeWrapper from '../bonus_code_wrapper';

const SignupOffersList = ({ bonusCodeInput, offers }) => {
  if (!offers) {
    return <Spinner />;
  }

  const welcomePhrase = (offers.length > 0
    && t('javascript.promo_hub.select_your_bonus', { name: userName('signup') }))
    || t('javascript.promo_hub.welcome_to', { site: bvVar('siteName'), name: userName('signup') });

  const btnProperties = (offers.length > 0
    && {
      label: t('javascript.deposit_now_without_bonus'),
      secondary: true,
      inverse: true,
    })
    || {
      label: t('javascript.deposit_now'),
      primary: true,
    };

  return (
    <>
      <p className="offers-welcome-message">{welcomePhrase}</p>
      { bonusCodeInput && bonusCodeInput.position === 'top' && <BonusCodeWrapper appId="signup" /> }
      <OffersList offerComponent={SignupOffer} rootPath="/account/offers" appId="signup" />
      { bonusCodeInput && bonusCodeInput.position === 'bottom' && <BonusCodeWrapper appId="signup" /> }
      <div className="offers-welcome-button-wrapper">
        <Button
          {...btnProperties}
          onClick={navigateToDeposit('signup')}
          className="offers-welcome-button"
        />
      </div>
    </>
  );
};

SignupOffersList.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.Object).isRequired,
  bonusCodeInput: PropTypes.isRequired,
  appId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  offers: getOffers(state, 'signup'),
  bonusCodeInput: getBonusCodeInput(state, 'signup'),
});

export default connect(mapStateToProps)(SignupOffersList);
