import { createElement } from 'react';
import PropTypes from 'prop-types';
import { Card, Image } from 'bv-components';
import { cmsAssetPath } from 'bv';
import Labels from 'Offers/components/labels';
import OfferRibbon from './offer_ribbon';

const Offer = (props) => {
  const {
    children, image, ribbon, showLabels, showTitle, summary, title, titleTagName,
  } = props;
  return (
    <Card className="offers-card">
      <div className="offers-card__image">
        <Image src={cmsAssetPath(image)} alt={title} />
        {ribbon && <OfferRibbon source={ribbon} /> }
      </div>
      {showLabels && <Labels {...props} />}
      { showTitle && createElement(
        titleTagName,
        { className: 'offers-card__title' },
        title,
      )}
      <div className="offers-card__description">{summary}</div>

      {children}
    </Card>
  );
};

Offer.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  expiration: PropTypes.string,
  children: PropTypes.node,
  ribbon: PropTypes.string,
  showLabels: PropTypes.bool,
  showTitle: PropTypes.bool,
  titleTagName: PropTypes.string,
};

Offer.defaultProps = {
  ribbon: null,
  expiration: null,
  children: null,
  showLabels: true,
  showTitle: true,
  titleTagName: 'div',
};

export default Offer;
