const getFromAppState = (name) => (state, appId) => state.offers[appId]
  && state.offers[appId][name];

export const getOffers = getFromAppState('list');
export const getBonusOffersResp = getFromAppState('bonusOffersResp');
export const getBonusCodeInput = getFromAppState('bonusCodeInput');
export const getOffer = getFromAppState('offer');
export const getIsFetching = getFromAppState('isFetching');
export const getScroll = getFromAppState('scroll');
export const getIsOptingIn = getFromAppState('isOptingIn');
export const getOptInOfferId = getFromAppState('optInOfferId');

export const getBalance = getFromAppState('balance');
export const getWallets = getFromAppState('wallets');
export const getWallet = getFromAppState('wallet');

export const getFilteredOffers = (state, appId, offersFilter) => {
  const offers = getOffers(state, appId);
  return offers && offers.filter((offer) => (
    !offersFilter || offersFilter === 'all' || offer.products.map((p) => p.toLowerCase()).includes(offersFilter.toLowerCase())
  ));
};

export const shouldFetchOffers = (state, appId) => {
  const appState = state.offers[appId];

  return !appState || (
    (!appState.list || !appState.list.length || appState.didInvalidate) && !appState.isFetching
  );
};
