import { useContext } from 'react';
import PropTypes from 'prop-types';
import ClaimButton from 'Offers/components/claim_button';
import CtaButton from 'Offers/components/cta_button';
import { OffersContext } from '../app';

const WalletsButton = ({ claimable, cta, id }) => {
  const { appId } = useContext(OffersContext) || {};

  return claimable
    ? <ClaimButton id={id} appId={appId} />
    : <CtaButton {...cta} />;
};

WalletsButton.propTypes = {
  claimable: PropTypes.bool.isRequired,
  cta: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.number.isRequired,
};

export default WalletsButton;
