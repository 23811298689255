import { t } from 'bv-i18n';

const { showDangerMessage } = window.ModalHelper;

export default (successKey, failure) => (response) => {
  if (response.success) {
    window.location.reload();
  } else {
    showDangerMessage({
      message: failure(response),
      actions: [{ label: t('javascript.close'), danger: true, close: true }],
      dataLayer: {
        error_message: failure(response),
        error_key: failure(response),
        error_code: 'E0x001',
      },
    });
  }
};
