import { addMiddleware } from 'redux-dynamic-middlewares';
import reducer from './duck';
import offersMiddleware from './middleware';

const { store, addReducers } = window.reduxState;

addReducers({
  offers: reducer,
});

addMiddleware(offersMiddleware);

export default store;
