import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import { optIn as optInAction } from 'Offers/duck';
import { optInProps } from 'Offers/config';
import { getIsOptingIn, getOptInOfferId } from 'Offers/selectors';

const OptInButton = ({
  appId, id, isOptingIn, optIn, optInButton, optInOfferId,
}) => {
  const loading = isOptingIn && id === optInOfferId;

  return (
    <Button
      label={optInButton || t('javascript.promo_hub.opt_in.button')}
      onClick={optIn}
      loading={loading}
      disabled={loading}
      primary
      {...optInProps(appId)}
    />
  );
};

OptInButton.propTypes = {
  id: PropTypes.number.isRequired,
  bonusCode: PropTypes.string.isRequired,
  optIn: PropTypes.func.isRequired,
  optInOfferId: PropTypes.number.isRequired,
  isOptingIn: PropTypes.bool.isRequired,
  appId: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  optInButton: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  isOptingIn: getIsOptingIn(state, ownProps.appId) || false,
  optInOfferId: getOptInOfferId(state, ownProps.appId) || null,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  optIn: (event) => {
    event.preventDefault();
    dispatch(optInAction(ownProps.appId, ownProps.id, ownProps.slug, ownProps.bonusCode));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OptInButton);
