import PropTypes from 'prop-types';
import classnames from 'classnames';

const statusDiv = (status, parsedStatus) => {
  if (status) {
    const statusDivClassName = classnames(
      'bvs-label', 'offers-card__label', 'offers-card__label--status', 'offers-card__label--top', {
        'is-warning': status === 'optin_available',
        'is-info': ['active', 'available'].includes(status),
        'is-success': ['completed', 'partially_completed'].includes(status),
        'is-danger': ['expired', 'failed'].includes(status),
      },
    );

    return <div className={statusDivClassName}>{parsedStatus}</div>;
  }

  return null;
};

const Labels = ({ expiration, parsedStatus, status }) => (
  <div className="offers-card__labels">
    {statusDiv(status, parsedStatus)}
    {expiration
      && <div className="bvs-label offers-card__label offers-card__label--bottom">{expiration}</div>}
  </div>
);

Labels.propTypes = {
  expiration: PropTypes.string,
  parsedStatus: PropTypes.string,
  status: PropTypes.string,
};

Labels.defaultProps = {
  parsedStatus: null,
  status: null,
  expiration: null,
};

export default Labels;
