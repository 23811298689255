import { t } from 'bv-i18n';
import { Link } from 'bv-components';

const LoginMessage = () => (
  <div className="offers-item">
    <div className="offers-banner">
      <div className="offers-banner__login">
        <Link to="/login">
          {t('javascript.promo_hub.login')}
        </Link>
        <p>{t('javascript.promo_hub.to_see_bonus_details')}</p>
      </div>
    </div>
  </div>
);

export default LoginMessage;
