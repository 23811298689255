import PropTypes from 'prop-types';
import { Card } from 'bv-components';

import BonusCodeFormContainer from 'Offers/components/bonus_code_form_container';
import BonusCodeOffersList from 'Offers/components/lists/bonus_code_offers_list';

const BonusCodeWrapper = ({ appId } = { appId: 'signup' }) => (
  <div className="offers-main-list bonus-code-wrapper">
    <Card className="offers-card bonus-code">
      <BonusCodeFormContainer appId={appId} />
      <BonusCodeOffersList appId={appId} />
    </Card>
  </div>
);

BonusCodeWrapper.propTypes = {
  appId: PropTypes.string.isRequired,
};

export default BonusCodeWrapper;
