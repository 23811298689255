import PropTypes from 'prop-types';
import OffersButton from 'Offers/components/offers_button';
import StepOutcome from 'Offers/components/step_outcome';
import { Step as StepCommon } from 'SharedComponents/step';

const Step = ({
  cta, enhancedOutcomes, offer, showOptIn, ...props
}) => (
  <StepCommon {...props}>
    {enhancedOutcomes && enhancedOutcomes.length > 0
      ? enhancedOutcomes.map((o) => <StepOutcome id={o.outcomeId} clickSource="offer-details-step" />)
      : <OffersButton cta={cta} showOptIn={showOptIn} offer={offer} />}
  </StepCommon>
);

Step.propTypes = {
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  cta: PropTypes.instanceOf(Object).isRequired,
  award: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  extraInfo: PropTypes.string,
  showOptIn: PropTypes.bool.isRequired,
  offer: PropTypes.instanceOf(Object).isRequired,
  enhancedOutcomes: PropTypes.instanceOf(Array).isRequired,
};

Step.defaultProps = {
  extraInfo: '',
};

export default Step;
