import MainOffersList from 'Offers/components/lists/main_offers_list';
import SignupOffersList from 'Offers/components/lists/signup_offers_list';

let store = {
  main: {
    listUrl: '/bv_api/offers',
    listComponent: MainOffersList,
    showLabels: true,
  },
  signup: {
    listUrl: '/bv_api/offers?signup=true',
    listComponent: SignupOffersList,
    showLabels: false,
  },
};

export const set = (appId, config = {}) => {
  store = {
    ...store,
    [appId]: {
      ...(store[appId]),
      ...config,
    },
  };
};

const getConfig = (name) => (appId) => store[appId] && store[appId][name];

export const listUrl = getConfig('listUrl');
export const listComponent = getConfig('listComponent');
export const showBackButton = getConfig('showBackButton');
export const showLabels = getConfig('showLabels');
export const optInProps = getConfig('optInProps');
export const userName = getConfig('userName');
export const optInHandler = getConfig('optInHandler');
export const offerParser = getConfig('offerParser');
export const walletsParser = getConfig('walletsParser');
export const navigateToDeposit = getConfig('navigateToDeposit');

export default () => store;
