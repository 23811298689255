import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { connect } from 'react-redux';
import { SeoTitle, Spinner, ContentLayout } from 'bv-components';
import { fetchWallets as fetchWalletsAction } from 'Offers/duck';
import { getIsFetching, getWallets } from 'Offers/selectors';
import { useLoggedIn } from 'bv-hooks';
import WalletItemParent from 'Offers/components/wallet/wallet_item_parent';
import BonusBalance from 'Offers/components/bonus_balance';

import { showBackButton } from 'Offers/config';

const WalletsList = ({
  appId, fetchWallets, isFetching, wallets,
}) => {
  useEffect(() => { fetchWallets(); }, []);
  const loggedIn = useLoggedIn();

  const listContent = () => {
    if (!wallets || isFetching) {
      return <Spinner />;
    }

    if (wallets.length === 0) {
      return (
        <div className="offers-main-list no-offers">
          <div className="bvs-modal-section__empty">{t('javascript.promo_hub.no_wallets')}</div>
        </div>
      );
    }

    return (
      <div className="offers-main-list">
        {wallets.map((wallet) => (
          <WalletItemParent wallet={wallet} key={wallet.id} />
        ))}
      </div>
    );
  };

  return (
    <ContentLayout>
      <SeoTitle defaultHeading={t('javascript.promo_hub.my_bonus_funds')} />
      <div>
        {showBackButton(appId) && loggedIn && <BonusBalance goBack />}
        {listContent()}
      </div>
    </ContentLayout>
  );
};

WalletsList.propTypes = {
  appId: PropTypes.string.isRequired,
  fetchWallets: PropTypes.func.isRequired,
  wallets: PropTypes.arrayOf(PropTypes.Object).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  wallets: getWallets(state, ownProps.appId),
  isFetching: getIsFetching(state, ownProps.appId),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchWallets: () => {
    dispatch(fetchWalletsAction(ownProps.appId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletsList);
export { WalletsList };
