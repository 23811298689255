import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';

const BonusCodeFormView = ({
  bonusCode,
  error,
  onErrorClick,
  inputRef,
  onSubmit,
  loading,
  offersResp,
  inputFocused,
  setInputFocused,
  onBonusCodeChange,
}) => (
  <>
    <div className="bvs-header">{t('javascript.promo_hub.bonus_code_input.do_you_have_a_code')}</div>
    <form onSubmit={onSubmit} className="offers-card__form">
      <div className="flex">
        <div className="flex--grow">
          <div
            className={classnames('offers-card__field bvs-form-group', {
              error: !!error,
              success: !error && offersResp.offers,
            })}
          >
            <input
              ref={inputRef}
              autoComplete="off"
              className={classnames('bvs-form-control', {
                error: !!error,
                active: inputFocused || bonusCode,
              })}
              type="text"
              name="bonus_code"
              value={bonusCode || ''}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              onChange={(e) => onBonusCodeChange(e.target.value)}
            />
            <label htmlFor="password" className="bvs-form-control-placeholder">
              {t('javascript.promo_hub.bonus_code_input.bonus_code_redeem')}
            </label>
            { error && <span className="bvs-form-error-msg">{error}</span> }
            { true && <span className="bvs-icon is-check" /> }
            { error && <span className="bvs-icon is-close" onClick={onErrorClick} /> }
          </div>
        </div>
        <Button
          disabled={loading || !bonusCode}
          type="submit"
          primary
          label={t('javascript.promo_hub.bonus_code_input.apply')}
          loading={loading}
        />
      </div>
    </form>
  </>
);

BonusCodeFormView.propTypes = {
  bonusCode: PropTypes.isRequired,
  error: PropTypes.isRequired,
  inputRef: PropTypes.isRequired,
  onErrorClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  offersResp: PropTypes.isRequired,
  inputFocused: PropTypes.isRequired,
  setInputFocused: PropTypes.func.isRequired,
  onBonusCodeChange: PropTypes.func.isRequired,
};

export default BonusCodeFormView;

/* eslint jsx-a11y/no-static-element-interactions: off */
