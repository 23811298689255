import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Icon, Link } from 'bv-components';

const BackLink = ({ containerClass, linkTo }) => (
  <div className={containerClass}>
    <Link to={linkTo}>
      <Icon id="arrow-left" />
      {t('javascript.go_back')}
    </Link>
  </div>
);

BackLink.propTypes = {
  containerClass: PropTypes.string,
  linkTo: PropTypes.string,
};

BackLink.defaultProps = {
  containerClass: 'offers-back',
  linkTo: '/',
};

export default BackLink;
