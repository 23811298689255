import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { t } from 'bv-i18n';
import WagerProgress from './wager_progress';

const Step = ({
  award, className, disabled, extraInfo, index, status,
  text, type, children,
}) => {
  const stepClassName = classnames(className, 'offers-steps__step', {
    'offers-steps--completed': status === 'completed',
    'offers-steps--failed': ['failed', 'expired'].includes(status),
    'offers-steps--reward': type === 'reward',
    'offers-steps--disabled': disabled,
  });

  return (
    <li className={stepClassName}>
      <div className="offers-steps__circle">
        <div className="offers-steps__circle-content">
          <small>{t('javascript.promo_hub.step')}</small>
          <strong>{index}</strong>
        </div>
      </div>
      <div className="offers-steps__body">
        <ReactMarkdown source={text} softBreak="br" />
        {award && <WagerProgress {...award} phrase="spend_more" />}
        {extraInfo
          && <div className="offer-extra-info">{extraInfo}</div>}
        {children}
      </div>
    </li>
  );
};

Step.propTypes = {
  status: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  award: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  extraInfo: PropTypes.string,
  children: PropTypes.node,
};

Step.defaultProps = {
  status: '',
  type: '',
  award: null,
  className: '',
  disabled: false,
  extraInfo: '',
  children: null,
};

export default Step;
