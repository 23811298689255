import { createElement } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

// TODO: Refactor dynamic header component and add proptypes
/* eslint-disable react/prop-types */
const OfferRibbon = ({ source }) => (
  <div className="offers-card__ribbon">
    <ReactMarkdown
      source={source}
      softBreak="br"
      renderers={{
        Heading: ({ level, children }) => (
          createElement('div', { className: `bvs-h${level}` }, children)
        ),
      }}
    />
  </div>
);

OfferRibbon.propTypes = {
  source: PropTypes.string,
};

OfferRibbon.defaultProps = {
  source: null,
};

export default OfferRibbon;
