import PropTypes from 'prop-types';
import WalletInfo from 'Offers/components/wallet/wallet_info';
import { Card } from 'bv-components';

const WalletItemParent = (props) => (
  <div className="wallets-list">
    <Card className="wallets-card">
      <WalletInfo {...props} />
    </Card>
  </div>
);

WalletItemParent.propTypes = {
  wallet: PropTypes.instanceOf(Object),
  displayTerms: PropTypes.bool,
  displayExpiration: PropTypes.bool,
};

WalletItemParent.defaultProps = {
  wallet: {},
  displayTerms: true,
  displayExpiration: true,
};

export default WalletItemParent;
