import { t } from 'bv-i18n';
import Offer from 'Offers/components/offer';
import OptInButton from 'Offers/components/opt_in_button';

const SignupOffer = (props) => (
  <Offer {...props} showLabels={false}>
    <div className="offers-card__registration">
      <OptInButton {...props} />
      <span className="offers-card__link">{t('javascript.promo_hub.t_and_cs')}</span>
    </div>
  </Offer>
);

export default SignupOffer;
