import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ContentLayout } from 'bv-components';
import BannerContainer from 'Offers/components/banner_container';
import OffersFilters from 'Offers/components/offers_filters';
import Offer from 'Offers/components/offer';
import OffersList from 'Offers/components/lists/offers_list';
import { getBonusCodeInput } from 'Offers/selectors';
import { useLoggedIn } from 'bv-hooks';
import { getDefaultProduct } from 'Offers/helpers';
import BonusCodeWrapper from '../bonus_code_wrapper';

const MainOffersList = ({ bonusCodeInput }) => {
  const loggedIn = useLoggedIn();
  const { product } = useParams();
  const selectedFilter = product || getDefaultProduct();

  return (
    <ContentLayout showTabs>
      <div className="offers-navigation">
        <BannerContainer />
        <OffersFilters selectedFilter={selectedFilter} />
      </div>
      { loggedIn && bonusCodeInput && bonusCodeInput.position === 'top' && <BonusCodeWrapper appId="main" /> }
      <OffersList offerComponent={Offer} selectedFilter={selectedFilter} appId="main" rootPath="/offer" />
      { loggedIn && bonusCodeInput && bonusCodeInput.position === 'bottom' && <BonusCodeWrapper appId="main" /> }
    </ContentLayout>
  );
};

MainOffersList.propTypes = {
  bonusCodeInput: PropTypes.isRequired,
};

const mapStateToProps = (state) => ({
  bonusCodeInput: getBonusCodeInput(state, 'main'),
});

export default connect(mapStateToProps)(MainOffersList);
