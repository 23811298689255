import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { handleCta } from 'bv-services';
import OptInButton from 'Offers/components/opt_in_button';
import { OffersContext } from './app';

const OffersButton = ({ cta, offer, showOptIn }) => {
  const { appId } = useContext(OffersContext) || {};

  if (showOptIn) {
    return (
      <OptInButton
        appId={appId}
        id={offer.id}
        bonusCode={offer.bonusCode}
        optInButton={offer.optInButton}
      />
    );
  } if (cta) {
    const queryString = `path=offers&promo_id=${offer.id}`;
    const opts = {
      label: cta.name,
      [cta.type || 'primary']: true,
      onClick: () => handleCta({ ...cta, queryString }),
    };

    return <Button {...opts} />;
  }

  return null;
};

OffersButton.propTypes = {
  showOptIn: PropTypes.bool.isRequired,
  offer: PropTypes.instanceOf(Object).isRequired,
  cta: PropTypes.instanceOf(Object),
};

OffersButton.defaultProps = {
  cta: null,
};

export default OffersButton;
