import { ContentLayout, Spinner } from 'bv-components';
import withLazyLoad from 'bv-lazy-load';

const mainRoutesLazyLoad = withLazyLoad(<ContentLayout><Spinner /></ContentLayout>);

export const Casino = mainRoutesLazyLoad('casino');
export const Virtuals = mainRoutesLazyLoad('virtuals');
export const SportHome = mainRoutesLazyLoad('sportHome');
export const SelfExclude = mainRoutesLazyLoad('selfExclude');
export const Lottery = mainRoutesLazyLoad('lottery');
export const BetCalculator = mainRoutesLazyLoad('betCalculator');
export const Offers = mainRoutesLazyLoad('offers');
export const Competitions = mainRoutesLazyLoad('competitions');
export const SportsHome = mainRoutesLazyLoad('sportsHome');
export const StaticPages = mainRoutesLazyLoad('staticPages');
export const BetBuilderFixtures = mainRoutesLazyLoad('betBuilderFixtures');
export const BetBuilder = mainRoutesLazyLoad('betBuilder');
export const SportSectionsCustomList = mainRoutesLazyLoad('sportSectionsCustomList');
export const InPlayOverview = mainRoutesLazyLoad('inPlayOverview');
export const GreyHounds = mainRoutesLazyLoad('greyHounds');
export const BetFinder = mainRoutesLazyLoad('betFinder');
export const HorseRacing = mainRoutesLazyLoad('horseRacing');
export const EventLevel = mainRoutesLazyLoad('eventLevel');
export const NextEvent = mainRoutesLazyLoad('nextEvent');
export const FtpSportLaunch = mainRoutesLazyLoad('sportsbookFtpSportLaunch');
export const ErrorPage = mainRoutesLazyLoad('errorPage');
export const CrackTheCodeZone = mainRoutesLazyLoad('crackTheCodeZone');
