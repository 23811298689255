import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { subscribe } from 'bv-services/internal-event-bus';

import App from './components/app';
import { set } from './config';
import { addApp } from './duck';
import store from './store';
import { refreshCurrentOffer } from './helpers';

const OffersApp = ({ id, config }) => {
  useEffect(() => {
    store.dispatch(addApp(id));
    set(id, config);
    subscribe('OfferOptIn', refreshCurrentOffer);
  }, []);

  return <App id={id} />;
};

OffersApp.defaultProps = {
  id: 'main',
  config: {},
};

OffersApp.propTypes = {
  id: PropTypes.string,
  config: PropTypes.instanceOf(Object),
};

export default OffersApp;
