import { getJSON, postJSON } from 'bv-fetch';
import { handleCta } from 'bv-services';
import { loggedIn } from 'bv-helpers/session';
import { listUrl, offerParser, walletsParser } from 'Offers/config';
import { setFragment } from 'bv-helpers/location';
import { isModal, history, setMainContextPath } from 'bv-services/route';

export const doFetchOffers = (appId, bonusCode = undefined) => (
  getJSON(listUrl(appId), { l: loggedIn(), bonus_code: bonusCode })
);

export const doFetchOffer = (appId, slug, id, bonusCode = undefined, ignoreErrors = false) => (
  getJSON(`/bv_api/offers/${slug}`, { id, l: loggedIn(), bonus_code: bonusCode })
    .then(offerParser(appId))
    .catch((e) => {
      if (ignoreErrors) return;
      if (e.status === 403) {
        if (loggedIn()) {
          if (isModal(history.location.pathname)) {
            setMainContextPath('/offer');
          } else {
            setFragment('/offer');
          }
        } else {
          handleCta({
            action: 'ACCOUNTSECTION',
            accountSection: 'LOGIN',
          });
        }
      }
    })
);

export const doOptIn = (id, bonusCode) => (
  postJSON('/bv_api/offers/opt_in', { id, bonus_code: bonusCode })
);

export const doFetchBalance = () => (
  getJSON('/bv_api/wallets/balance')
    .then((balance) => balance.toFixed(2))
    .catch(() => {})
);

export const doFetchWallet = (id) => (
  getJSON(`/bv_api/wallets/${id}`).catch(() => {
    handleCta({
      action: 'ACCOUNTSECTION',
      accountSection: 'LOGIN',
    });
  })
);

export const doFetchWallets = (appId) => (
  getJSON('/bv_api/wallets')
    .then(walletsParser(appId))
    .catch(() => ([]))
);

export const doClaim = (id) => (
  postJSON('/bv_api/wallets/claim', { id })
);

export default doFetchOffers;
