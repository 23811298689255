import { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Spinner, Link } from 'bv-components';
import { useLoggedIn } from 'bv-hooks';
import { fetchOffersIfNeeded as fetchOffersIfNeededAction } from 'Offers/duck';
import { getFilteredOffers, getIsFetching, getIsOptingIn } from 'Offers/selectors';
import { useSaveScrollPos, offerLink } from 'Offers/helpers';

import OffersNotFound from '../offers_not_found';

const OffersList = ({
  appId, fetchOffersIfNeeded, isFetching, isOptingIn, offerComponent, offers,
}) => {
  const ref = createRef();
  const saveScrollPos = useSaveScrollPos({ appId, ref });
  const loggedIn = useLoggedIn();
  const Offer = offerComponent;

  useEffect(() => {
    fetchOffersIfNeeded();
  }, [loggedIn]);

  if (!offers || isFetching) {
    return <Spinner />;
  }

  if (offers.length === 0) {
    return <OffersNotFound />;
  }

  return (
    <div className="offers-main-list" ref={ref}>
      {offers.map((offer) => (
        <Link
          to={offerLink(appId, offer)}
          data-react="true"
          onClick={saveScrollPos}
          key={offer.id}
          className={classnames('offers-main-list__item', { 'is-inactive': isOptingIn })}
        >
          <Offer {...offer} appId={appId} key={offer.slug} />
        </Link>
      ))}
    </div>
  );
};

OffersList.propTypes = {
  appId: PropTypes.string.isRequired,
  fetchOffersIfNeeded: PropTypes.func.isRequired,
  offers: PropTypes.arrayOf(PropTypes.Object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isOptingIn: PropTypes.bool.isRequired,
  offerComponent: PropTypes.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  offers: getFilteredOffers(state, ownProps.appId, ownProps.selectedFilter),
  isFetching: getIsFetching(state, ownProps.appId),
  isOptingIn: getIsOptingIn(state, ownProps.appId),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchOffersIfNeeded: () => {
    dispatch(fetchOffersIfNeededAction(ownProps.appId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OffersList);
