import LoginMessage from 'Offers/components/login_msg';
import BonusBalance from 'Offers/components/bonus_balance';
import { useLoggedIn } from 'bv-hooks';

const BonusWrapper = () => {
  const loggedIn = useLoggedIn();

  return (
    <section className="id-banners_wrapper site-top-banner-wrapper">
      <div className="site-main-banner-wrapper bonus-banner-wrapper">
        <div className="bv-slider">
          <div className="bv-slider-inner">
            {loggedIn ? <BonusBalance /> : <LoginMessage />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BonusWrapper;
