import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { toCurrency } from 'bv-helpers/number';

const WagerProgress = ({ phrase, progressPercentage, remainingAmount }) => {
  const humanizedAmount = (amount) => parseFloat(amount).toFixed(2);

  return (
    <div className="offers-steps__award">
      <div>
        {t(`javascript.promo_hub.${phrase}`, {
          amount: toCurrency(humanizedAmount(remainingAmount)),
        })}
      </div>
      <div className="offers-steps__award-bar">
        <div
          className="offers-steps__award-progress"
          style={{ width: `${progressPercentage}%` }}
        />
      </div>
    </div>
  );
};

WagerProgress.propTypes = {
  remainingAmount: PropTypes.string.isRequired,
  progressPercentage: PropTypes.number.isRequired,
  phrase: PropTypes.string.isRequired,
};

export default WagerProgress;
