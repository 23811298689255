import { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { useLocation, useParams } from 'react-router-dom';
import { t } from 'bv-i18n';
import { useLoggedIn } from 'bv-hooks';
import { connect } from 'react-redux';
import {
  Button, Card, SeoTitle, Spinner, ContentLayout, ExpandCollapseCard,
} from 'bv-components';
import { fetchOffer as fetchOfferAction, resetOffer as resetOfferAction } from 'Offers/duck';
import { getOffer, getIsFetching } from 'Offers/selectors';
import { showBackButton, showLabels } from 'Offers/config';
import { setMetaInfo } from 'bv-services/seo';
import BackLink from 'Offers/components/back_link';
import Step from 'Offers/components/step';
import { ErrorPage } from 'Index/features/routing/components/main_routes/lazy_components';
import classnames from 'classnames';
import Offer from './offer';

const ref = createRef();

const OfferDetail = ({
  appId, isFetching, fetchOffer, offer, resetOffer, rootPath,
}) => {
  const location = useLocation();
  const { slug, id } = useParams();
  const bonusCode = new URLSearchParams(location.search).get('bonus_code');
  const loggedIn = useLoggedIn();

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.parentNode.parentNode.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
    fetchOffer(slug, id, bonusCode);
    return resetOffer;
  }, [location.pathname, loggedIn]);

  const stepClassName = (step) => {
    if (step) {
      return classnames({
        'offer-next-step--completed': step.status === 'completed',
        'offer-next-step--failed': step.status === 'failed',
        'offer-next-step--disabled': step.disabled,
      });
    }

    return '';
  };

  const renderOfferDetail = () => {
    if (isFetching !== false) {
      return <Spinner />;
    }

    if (offer) {
      const stepsClassName = classnames('offer-steps', {
        'offer-first-step-completed': offer.steps[0].status === 'completed',
        'offer-first-step-failed': offer.steps[0].status === 'failed',
      });

      return (
        <>
          <div className="offers-card-heading">
            <SeoTitle defaultHeading={offer.title} />
          </div>
          <div className="offers-card-details">
            {showBackButton(appId) && <BackLink linkTo={rootPath} />}

            <div className="offer-card-details-wrapper">
              <Offer
                {...offer}
                showLabels={showLabels(appId)}
                showTitle={false}
              />
            </div>

            <Card className="offers-card">
              <ul className={stepsClassName}>
                {offer.steps.map((step, index) => (
                  <Step
                    index={index + 1}
                    className={stepClassName(offer.steps[index + 1])}
                    offer={offer}
                    {...step}
                  />
                ))}
              </ul>
            </Card>
            <div className="offers-terms-conditions">
              <h4 className="bvs-header">{t('javascript.refer_a_friend.terms_and_conditions_title')}</h4>
              <ExpandCollapseCard fadeOutOnEnd>
                <ReactMarkdown
                  source={offer.termsAndConditions}
                  softBreak="br"
                  className="offers-card-terms page-contents"
                />
              </ExpandCollapseCard>
            </div>
          </div>
        </>
      );
    }

    const rootOffersPageButton = () => (
      <Button to={rootPath} primary className="error-page__button">
        {t('javascript.promo_hub.not_found.see_all_offers')}
      </Button>
    );

    return (
      <>
        {showBackButton(appId) && <BackLink linkTo={rootPath} />}
        <ErrorPage showAdditionalButtons={rootOffersPageButton} showContentLayout />
      </>
    );
  };

  useEffect(() => {
    setMetaInfo(() => {
      if (!offer) return null;

      return ({
        id: offer.slug,
        type: 'offer',
        data: { ...offer },
      });
    });
  }, [offer]);

  return appId === 'signup'
    ? <div ref={ref}>{renderOfferDetail()}</div>
    : <ContentLayout>{renderOfferDetail()}</ContentLayout>;
};

OfferDetail.propTypes = {
  appId: PropTypes.string.isRequired,
  fetchOffer: PropTypes.func.isRequired,
  resetOffer: PropTypes.func.isRequired,
  offer: PropTypes.instanceOf(Object),
  isFetching: PropTypes.bool.isRequired,
  rootPath: PropTypes.string.isRequired,
};

OfferDetail.defaultProps = {
  offer: null,
};

const mapStateToProps = (state, ownProps) => ({
  offer: getOffer(state, ownProps.appId),
  isFetching: getIsFetching(state, ownProps.appId),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchOffer: (slug, id, bonusCode) => (
    dispatch(fetchOfferAction(ownProps.appId, slug, id, bonusCode))
  ),
  resetOffer: () => (
    dispatch(resetOfferAction(ownProps.appId))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetail);
